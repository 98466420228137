import React from 'react'
import { Link } from 'gatsby'
import './TitleBar.scss'

const TitleBar = ({ title }) => (
  <div className="title-bar has-text-centered">
    <div className="container">
      <h2>{title}</h2>
    </div>
  </div>
)

export default TitleBar
