import React from 'react'
import * as Scroll from 'react-scroll'
import {
  Link as scrollLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll'

class ButtonLink extends React.Component {
  scrollToSection(e, id) {
    e.preventDefault()
    if (id === '/') {
      scroll.scrollTo(0)
    } else {
      scroll.scrollTo(document.getElementById(id).offsetTop - 80)
    }
  }

  render() {
    return (
      <a
        onClick={e => this.scrollToSection(e, this.props.to)}
        className={`button is-dark is-${this.props.type}`}
      >
        {this.props.text}
      </a>
    )
  }
}

export default ButtonLink
