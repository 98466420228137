import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import * as EmailValidator from 'email-validator'
import { v4 } from 'uuid'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import ContactForm from '../components/ContactForm/ContactForm'
import DesignServicesIcons from '../components/DesignServicesIcons/DesignServicesIcons'
import HomeIntro from '../components/HomeIntro/HomeIntro'
import HomeCarousel from '../components/HomeCarousel/HomeCarousel'
import Projects from '../components/Projects/Projects'
import Testimonials from '../components/Testimonials/Testimonials'
import TitleBar from '../components/TitleBar/TitleBar'
import '../styles/pages/home.scss'

const About = ({ content }) => (
  <div id="about">
    <div className="container">
      <TitleBar title="Good design is good business" />
      <div className="inner">
        <div className="columns">
          <div className="column is-10 is-offset-1 has-text-centered">
            <div className="columns is-multiline">
              {content.map(c => (
                <div className="column is-6" key={v4()}>
                  <h3 className="title">{c.title}</h3>
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{ __html: c.content }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const Clients = ({ clients }) => {
  const logos = clients.map(c => (
    <div className="column" key={v4()}>
      <Img fixed={c.node.featured_media.localFile.childImageSharp.fixed} />
    </div>
  ))

  return (
    <div id="clients">
      <TitleBar title="Clients" />
      <div className="inner">
        <div className="container">
          <div className="columns">{logos}</div>
        </div>
      </div>
    </div>
  )
}

export const PageTemplate = ({
  slides,
  projects,
  testimonials,
  aboutContent,
}) => (
  <div>
    <HomeCarousel slides={slides} />
    <Testimonials testimonials={testimonials} />
    <DesignServicesIcons />
    <Projects projects={projects} />
    <About content={aboutContent} />
    <ContactForm />
  </div>
)

const HomePage = ({ data }) => {
  const { wordpressPage: page } = data
  console.log(data)

  return (
    <Layout>
      <SEO title="Home" description={page.acf.meta_description} />
      <PageTemplate
        slides={data.allWordpressWpSlides.edges}
        projects={data.allWordpressWpProjects.edges}
        testimonials={data.allWordpressWpTestimonials.edges}
        aboutContent={page.acf.about_us}
      />
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query HomePage($id: String!) {
    wordpressPage(id: { eq: $id }) {
      acf {
        meta_description
        about_us_text
        about_us {
          title
          content
        }
      }
    }
    allWordpressWpClients(sort: { fields: date, order: DESC }) {
      edges {
        node {
          title
          featured_media {
            localFile {
              childImageSharp {
                fixed(width: 260, height: 126, quality: 100) {
                  base64
                  width
                  height
                  src
                  srcSet
                }
              }
            }
          }
          acf {
            website_url
          }
        }
      }
    }
    allWordpressWpProjects(sort: { fields: date, order: DESC }, limit: 11) {
      edges {
        node {
          title
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    allWordpressWpTestimonials(sort: { fields: date, order: DESC }, limit: 9) {
      edges {
        node {
          title
          content
          featured_media {
            localFile {
              childImageSharp {
                fixed(width: 150, height: 150, quality: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
    allWordpressWpSlides(sort: { fields: title, order: ASC }) {
      edges {
        node {
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
