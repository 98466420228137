import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, navigate } from 'gatsby'
import * as EmailValidator from 'email-validator'
import TitleBar from '../TitleBar/TitleBar'
import './ContactForm.scss'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { submitError: null }
  }

  handleChange = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value
    this.setState({ [e.target.name]: value })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState({
      submitError: null,
      invalidEmail: false,
    })

    if (
      this.state.name &&
      this.state.email &&
      this.state.telephone &&
      this.state.message &&
      EmailValidator.validate(this.state.email)
    ) {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': 'contact',
          ...this.state,
        }),
      })
        .then(() => {
          navigate('/thanks')
        })
        .catch(error => alert(error))
    } else {
      this.setState({
        submitError: 'Please make sure all required fields are filled in.',
        invalidEmail: !EmailValidator.validate(this.state.email),
      })
    }
  }

  render() {
    return (
      <div id="contact" className="container">
        <TitleBar title="Get in touch" />
        <div className="inner">
          <div className="contact-details has-text-centered">
            <ul>
              <li>
                <strong>t:</strong> 01423 858 626
              </li>
              <li>
                <strong>m:</strong> 07740 776 007
              </li>
              <li>
                <strong>e:</strong> info@geswhat.co.uk
              </li>
              <li>
                <strong>w:</strong> www.geswhat.co.uk
              </li>
            </ul>
          </div>
          <div className="columns">
            <div className="column is-6 is-offset-3 contact-form-wrapper">
              <form
                name="contact"
                method="post"
                action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                <input type="hidden" name="form-name" value="contact" />
                <p hidden>
                  <label>
                    Don’t fill this out:{' '}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </p>

                <div className="field">
                  <div className="control">
                    <label>Name*</label>
                    <input
                      className={`input ${
                        this.state.submitError && !this.state.name
                          ? 'is-danger'
                          : ''
                      }`}
                      type="text"
                      name="name"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <label>Email Address*</label>
                    <input
                      className={`input ${
                        this.state.submitError &&
                        (!this.state.email || this.state.invalidEmail)
                          ? 'is-danger'
                          : ''
                      }`}
                      type="text"
                      name="email"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <label>Telephone*</label>
                    <input
                      className={`input ${
                        this.state.submitError && !this.state.telephone
                          ? 'is-danger'
                          : ''
                      }`}
                      type="text"
                      name="telephone"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <label>Message*</label>
                    <textarea
                      className={`textarea ${
                        this.state.submitError && !this.state.message
                          ? 'is-danger'
                          : ''
                      }`}
                      type="text"
                      name="message"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <p className="has-text-right">
                  <button className="button is-dark submit" type="submit">
                    <span>Send</span>
                  </button>
                </p>
                {this.state.submitError && (
                  <div className="notification is-danger">
                    {this.state.submitError}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
