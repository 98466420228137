import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { v4 } from 'uuid'
import { chunk } from 'lodash'
import TitleBar from '../TitleBar/TitleBar'
import './Testimonials.scss'

const TestimonialsRow = ({ row }) => {
  const content = row.map(t => (
    <div className="column is-4 has-text-centered" key={v4()}>
      <div className="testimonial">
        <Img fixed={t.node.featured_media.localFile.childImageSharp.fixed} />
        <div
          className="testimonial-content"
          dangerouslySetInnerHTML={{ __html: t.node.content }}
        />
        <div
          className="testimonial-name"
          dangerouslySetInnerHTML={{ __html: t.node.title }}
        />
      </div>
    </div>
  ))
  return <div className="columns">{content}</div>
}

const Testimonials = ({ testimonials }) => {
  const chunked = chunk(testimonials, 3)
  return (
    <div id="testimonials" className="testimonials-wrapper container">
      <TitleBar title="What our customers say" />
      <div className="inner">
        {chunked.map(row => (
          <TestimonialsRow row={row} key={v4()} />
        ))}
      </div>
    </div>
  )
}

export default Testimonials
