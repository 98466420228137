import React from 'react'
import { Link } from 'gatsby'
import { v4 } from 'uuid'
import TitleBar from '../TitleBar/TitleBar'
import './DesignServicesIcons.scss'

import Adverts from '../../img/services-icons/Adverts.svg'
import Booklets from '../../img/services-icons/Booklets.svg'
import Branding from '../../img/services-icons/Branding.svg'
import Brochures from '../../img/services-icons/Brochures.svg'
import Catalogues from '../../img/services-icons/Catalogues.svg'
import Datasheets from '../../img/services-icons/Datasheets.svg'
import Exhibition from '../../img/services-icons/Exhibition.svg'
import Flyers from '../../img/services-icons/Flyers.svg'
import GUI from '../../img/services-icons/GUI.svg'
import IconSets from '../../img/services-icons/IconSets.svg'
import Infographics from '../../img/services-icons/Infographics.svg'
import Livery from '../../img/services-icons/Livery.svg'
import Logo from '../../img/services-icons/Logo.svg'
import Posters from '../../img/services-icons/Posters.svg'
import Signage from '../../img/services-icons/Signage.svg'
import SocialMedia from '../../img/services-icons/SocialMedia.svg'
import Stationary from '../../img/services-icons/Stationary.svg'
import WebsiteDesign from '../../img/services-icons/WebsiteDesign.svg'

const services = [
  { icon: Logo, text: 'Logo' },
  { icon: Branding, text: 'Branding' },
  { icon: Brochures, text: 'Brochures' },
  { icon: Catalogues, text: 'Catalogues' },
  { icon: Booklets, text: 'Booklets' },
  { icon: Flyers, text: 'Flyers' },

  { icon: Stationary, text: 'Stationery' },
  { icon: Posters, text: 'Posters' },
  { icon: Signage, text: 'Signage' },
  { icon: Livery, text: 'Livery' },
  { icon: Exhibition, text: 'Exhibition' },
  { icon: Adverts, text: 'Adverts' },

  { icon: Datasheets, text: 'Datasheets' },
  { icon: IconSets, text: 'Icon Sets' },
  { icon: GUI, text: 'GUI' },
  { icon: WebsiteDesign, text: 'Website Design' },
  { icon: SocialMedia, text: 'Social Media' },
  { icon: Infographics, text: 'Infographics' },
]

const DesignServicesIcons = () => {
  const rows = services.map(s => (
    <div
      className="service column is-6-mobile is-3-tablet is-2-widescreen has-text-centered"
      key={v4()}
    >
      <img src={s.icon} />
      <h4>{s.text}</h4>
    </div>
  ))

  return (
    <div id="services" className="services-icons-wrapper container">
      <TitleBar title="Design Services" />
      <div className="inner">
        <div
          className="service-icons columns is-multiline is-mobile"
          key={v4()}
        >
          {rows}
        </div>
      </div>
    </div>
  )
}

export default DesignServicesIcons
