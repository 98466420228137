import React from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Slider from 'react-slick'
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdDone,
} from 'react-icons/md'
import ButtonLink from '../ButtonLink'

import './HomeCarousel.scss'

class HomeCarousel extends React.Component {
  drawSlides(slides) {
    const slideContent = slides.map((s, i) => (
      <div key={i}>
        <Img fluid={s.node.featured_media.localFile.childImageSharp.fluid} />
        <div className="text-wrapper">
          <div className="inner">
            <p>Are you looking for</p>
            <h2>
              <i>Amazing</i>
              {' '}
design at
              <br />
              Affordable 
              {' '}
              <i>prices?</i>
            </h2>
            <ButtonLink to="contact" text="Get in touch" type="wide" />
          </div>
        </div>
      </div>
    ))

    return slideContent
  }

  render() {
    const settings = {
      autoplay: true,
      autoplaySpeed: 20000,
      arrows: false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      cssEase: 'linear',
    }

    return (
      <div className="slider-wrapper has-text-centered">
        <Slider
          ref={slider => (this.slider = slider)}
          className="home-carousel"
          {...settings}
        >
          {this.drawSlides(this.props.slides)}
        </Slider>
      </div>
    )
  }
}

export default HomeCarousel
