import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { v4 } from 'uuid'
import { chunk, remove, take } from 'lodash'
import Carousel, { Modal, ModalGateway } from 'react-images'
import { FiZoomIn } from 'react-icons/fi'
import TitleBar from '../TitleBar/TitleBar'
import './Projects.scss'

class Projects extends React.Component {
  state = {
    selectedIndex: 0,
    lightboxIsOpen: false,
  }
  toggleLightbox = selectedIndex => {
    this.setState(state => ({
      lightboxIsOpen: !state.lightboxIsOpen,
      selectedIndex,
    }))
  }

  drawEvenRow(projects, index) {
    return (
      <div className="columns">
        <div className="column is-4 double-project">
          {projects.items[0] && (
            <div className="project-item">
              <a
                onClick={() =>
                  this.toggleLightbox(projects.items[0].imageIndex)
                }
              >
                <FiZoomIn />
                <Img
                  fluid={
                    projects.items[0].node.featured_media.localFile
                      .childImageSharp.fluid
                  }
                />
              </a>
            </div>
          )}
          {projects.items[1] && (
            <div className="project-item">
              <a
                onClick={() =>
                  this.toggleLightbox(projects.items[1].imageIndex)
                }
              >
                <FiZoomIn />
                <Img
                  fluid={
                    projects.items[1].node.featured_media.localFile
                      .childImageSharp.fluid
                  }
                />
              </a>
            </div>
          )}
        </div>
        <div className="column is-8 project-item large-project-item">
          {projects.items[2] && (
            <a
              onClick={() => this.toggleLightbox(projects.items[2].imageIndex)}
            >
              <FiZoomIn />
              <Img
                fluid={
                  projects.items[2].node.featured_media.localFile
                    .childImageSharp.fluid
                }
              />
            </a>
          )}
        </div>
      </div>
    )
  }

  drawOddRow(projects, index) {
    const content = projects.items.map((project, projectIndex) => (
      <div className="project project-item column has-text-centered" key={v4()}>
        <a onClick={() => this.toggleLightbox(project.imageIndex)}>
          <FiZoomIn />
          <Img
            fluid={project.node.featured_media.localFile.childImageSharp.fluid}
          />
        </a>
      </div>
    ))

    return <div className="columns">{content}</div>
  }

  render() {
    const { selectedIndex, lightboxIsOpen } = this.state
    const images = this.props.projects.map(project => {
      return {
        src: project.node.featured_media.localFile.childImageSharp.fluid.src,
      }
    })

    let chunks = []
    const chunkSizes = [3, 3, 2, 3]
    let i = 0
    let projects = [...this.props.projects]

    chunkSizes.forEach((size, index) => {
      let chunk = {
        layout: index === 1 ? 'two-one' : 'columns',
      }
      chunk.items = remove(projects, (p, projectIndex) => {
        return projectIndex < size
      })
      chunk.items.map((c, cIndex) => {
        c.imageIndex = i + cIndex
      })
      chunks[index] = chunk
      i = i + size
    })

    const rows = chunks.map((chunk, index) => (
      <div className="projects-row" key={v4()}>
        {chunk.layout === 'two-one' && this.drawEvenRow(chunk, index)}
        {chunk.layout === 'columns' && this.drawOddRow(chunk, index)}
      </div>
    ))

    return (
      <div id="projects" className="projects-wrapper container">
        <TitleBar title="Projects" />
        <div className="inner">{rows}</div>
        <ModalGateway>
          {lightboxIsOpen ? (
            <Modal onClose={this.toggleLightbox}>
              <Carousel views={images} currentIndex={selectedIndex} />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    )
  }
}

export default Projects
